import React, { Component } from "react";
import Rte from "../atoms/rte";
import ButtonGroup from "../molecules/button-group";
import FreeText from "../organisms/free-text";

import "../../scss/organisms/_image-block.scss";

class sectionImageBlock extends Component {
  constructor(props) {
    super(props);

    this.values = this.props.values.sort((a, b) => a.node.priority - b.node.priority);
    this.hidePriority = this.props.hidePriority;
    this.priorityIntro = this.props.priorityIntro;
  }

  render() {
    return (
      <section className="image-block my-5">
        <div className="container">
          {this.priorityIntro && <FreeText data={this.priorityIntro} />}
          {this.values.map((value, idx) => (
            <div key={value.node.id} className={["row", "my-5", "align-items-center", idx % 2 === 0 ? "flex-row-reverse" : ""].join(" ")}>
              <div className={["col-12", "col-md-6", "col-lg-5", idx % 2 !== 0 ? "ml-auto" : "mr-auto"].join(" ")}>
                {!this.hidePriority && 
                <span className="priority">{ value.node.priority < 10 ? `0${value.node.priority}` : value.node.priority }</span>
                }                
                <h3 className="title mb-5">{value.node.title}</h3>
                { value.node.description && 
                <div className="rte mb-5">
                  <Rte content={value.node.description} />
                </div> }                
                { value.node.callToAction && <ButtonGroup buttons={[value.node.callToAction]} /> }
              </div>
              <div className={["col-12", "col-md-6", "col-lg-5", idx % 2 !== 0 ? "ml-auto" : "mr-auto"].join(" ")}>
                <div className="svg-image">
                  <svg viewBox="0 0 750 750" preserveAspectRatio="xMidYMid meet">
                <g>
                <path fill="#ffffff" d="M0,750c0,0,0,0,0-750h750c0,250,0,500,0,750C500,750,250,750,0,750z M439.8,709.6c8.3-0.7,24.2-1.2,39.9-3.5
		c25.5-3.7,50.4-9.9,74.2-19.8c24-10,46.7-22.2,66.1-39.6c15.8-14.2,26.6-32.4,36.5-51c13.2-24.7,23.4-50.7,31.4-77.5
		c11.1-37.6,17.5-76.1,18.3-115.3c0.3-15.8-1.2-31.8-2.9-47.6c-4.3-41.8-16.8-81.1-37.1-117.9c-24.9-45.3-59.8-81.4-101.7-111
		C532,103.4,496.7,85.8,459.9,71c-39.4-15.8-79.7-23.5-122.2-17.5c-33,4.7-63.7,15.8-92.4,32.4c-44.9,26-81.8,61.2-113.1,102.2
		c-23.7,31.1-42.8,65.1-58.5,100.9c-14.8,33.6-23,68.4-19.1,105.5c2.5,23.7,8.9,46.2,18.8,67.8c14.8,32,34.9,60.6,57.4,87.6
		c21.4,25.7,44.2,49.9,69.7,71.6c27.6,23.5,57.8,42.9,91,57.7C336.3,699.1,383.2,709.2,439.8,709.6z"/>
	<path fill="#339999" d="M439.8,709.6c-56.6-0.4-103.5-10.4-148.3-30.3c-33.2-14.7-63.3-34.2-91-57.7c-25.5-21.7-48.3-46-69.7-71.6
		c-22.6-27-42.6-55.6-57.4-87.6c-9.9-21.5-16.4-44-18.8-67.8c-3.8-37.1,4.4-71.9,19.1-105.5c15.7-35.8,34.8-69.8,58.5-100.9
		c31.3-41,68.2-76.2,113.1-102.2c28.7-16.6,59.4-27.7,92.4-32.4c42.5-6,82.8,1.6,122.2,17.5c36.8,14.8,72,32.4,104.5,55.3
		c42,29.6,76.8,65.7,101.7,111c20.3,36.9,32.8,76.1,37.1,117.9c1.6,15.8,3.2,31.7,2.9,47.6c-0.8,39.2-7.1,77.6-18.3,115.3
		c-8,26.9-18.2,52.8-31.4,77.5c-9.9,18.6-20.7,36.8-36.5,51c-19.4,17.4-42.1,29.7-66.1,39.6c-23.8,9.9-48.8,16.1-74.2,19.8
		C464,708.4,448.1,708.9,439.8,709.6z M702.9,395.1c-0.3-4.4-0.4-11.8-1.3-19.2c-2.1-18.1-3.5-36.5-7.2-54.3
		c-11-53-35.8-99.3-71.7-139.7c-8.8-9.9-18.8-18.8-28.6-27.8C571.5,133.3,546,116.5,519,102c-29.6-15.8-60.2-29.4-92.5-38.9
		c-31.4-9.2-63.3-11.3-95.5-5.6c-35,6.2-67.2,20-97.1,39.2c-25.9,16.7-49.3,36.3-69.8,59.1c-11.6,12.9-22.9,26.2-33.2,40.1
		c-29.1,39.7-52.6,82.3-66.9,129.6c-8.3,27.4-9.6,55.4-4.5,83.4c6.3,34.1,21,64.8,40,93.6c24.4,37.1,53.1,70.7,85.8,100.6
		c15.3,14,31.6,27.2,48.3,39.5c25.4,18.7,53.6,32.7,83.3,43.3c31.9,11.5,64.8,18.3,98.7,19.5c10.3,0.4,20.7,0.6,31-0.1
		c14.2-0.9,28.4-1.7,42.4-4.2c32.8-5.8,64.3-15.9,93.5-32.5c22.4-12.7,42.2-28.1,56.4-50.3c13.7-21.4,24.9-43.9,34.2-67.5
		C692.4,501.9,701.9,450.9,702.9,395.1z"/>
	  <path fill="#ffffff" d="M702.9,395.1c-1,55.8-10.5,106.8-29.8,155.9c-9.3,23.6-20.5,46.1-34.2,67.5c-14.2,22.2-34,37.5-56.4,50.3
		c-29.2,16.6-60.6,26.7-93.5,32.5c-13.9,2.5-28.2,3.3-42.4,4.2c-10.3,0.6-20.7,0.4-31,0.1c-33.9-1.2-66.8-8.1-98.7-19.5
		c-29.7-10.7-57.8-24.6-83.3-43.3c-16.7-12.3-33-25.5-48.3-39.5c-32.8-29.9-61.4-63.5-85.8-100.6c-18.9-28.7-33.7-59.5-40-93.6
		c-5.2-28-3.8-56,4.5-83.4c14.3-47.3,37.8-90,66.9-129.6c10.2-14,21.6-27.2,33.2-40.1c20.5-22.9,44-42.5,69.8-59.1
		c29.9-19.2,62-33,97.1-39.2c32.2-5.7,64.1-3.7,95.5,5.6c32.3,9.5,62.9,23.1,92.5,38.9c27,14.4,52.5,31.3,75.1,52.1
		c9.8,9,19.8,17.9,28.6,27.8c35.9,40.3,60.7,86.6,71.7,139.7c3.7,17.8,5,36.2,7.2,54.3C702.5,383.3,702.6,390.8,702.9,395.1z
		 M691.8,407.7c-1.1-14.8-1.4-32.4-4.1-49.6c-2.7-17.6-7-35.1-11.9-52.2c-4.5-15.8-9.8-31.5-16.4-46.6
		c-8.6-19.9-21.1-37.7-34.5-54.7c-7-8.9-14.1-17.9-22.1-25.9c-8.2-8.2-17.5-15.2-26.4-22.7c-22.3-18.8-47.3-33.4-74-44.6
		c-19.6-8.2-39.9-14.7-60.2-21.3c-12.4-4-25.2-7.1-38-9.9c-9.2-2-18.6-3.4-27.9-3.9c-17-0.9-34.1-1.6-51.1-1
		c-8.8,0.3-17.5,4-26.3,5.6c-16.5,2.9-31.8,9.4-46,17.4c-16.6,9.4-33,20-47.5,32.3c-15.6,13.2-30,28.2-42.9,44
		c-12.3,15-22.8,31.6-32.7,48.4c-8.7,14.9-16.2,30.6-22.7,46.6c-8.9,22.1-17.5,44.5-23.8,67.4c-5,18.1-7.5,37.1-9.1,55.9
		c-1.9,21.7,1.4,43.2,9.1,63.7c4,10.6,8.4,21.3,14,31.1c11.1,19.3,23,38.2,39.6,53.6c12.3,11.4,23.8,23.7,36.1,35.1
		c7.3,6.7,15.4,12.4,23.3,18.5c25.2,19.4,51.6,37.1,80.2,51c17.5,8.5,35.8,15.9,54.3,21.9c29,9.4,59.1,14,89.7,14.3
		c22.5,0.2,44.8-1.6,67-5.9c24.9-4.8,48.9-12.3,71.8-23c26.9-12.5,51.3-28.8,72.8-49.5c14.8-14.2,26.3-30.2,32.7-49.5
		c4.8-14.6,9.3-29.3,13.1-44.2c4.2-16.9,8.4-33.9,10.7-51.1C690.9,442.9,690.7,426.5,691.8,407.7z"/>
                </g>
                </svg>
                  <img src={value.node.image.fluid.src} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default sectionImageBlock;
